import { DateTime, Interval } from "luxon"
import React from "react"

const Time = ({ value, timezone }: { value: DateTime, timezone: string }) => {
  const sameDay = value.isValid && value.hasSame(DateTime.local(), "day")
  return (
    <div>
      {sameDay ? null : (
        <div style={{ opacity: 0.66 }}>{value.setZone(timezone).toFormat("d LLL")}</div>
      )}
      <div>{value.setZone(timezone).toFormat("h:mma")}</div>
    </div>
  )
}

function Payment ({ plate, amount, timezone, ...props }: any): JSX.Element {
  const lo = DateTime.fromISO(props.lo)
  const hi = DateTime.fromISO(props.hi)

  const duration = Interval.fromDateTimes(lo, hi).toDuration()

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      {lo.isValid || hi.isValid ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          {duration.isValid ? (
            <div
              style={{
                display: "flex",
                fontSize: "5vmin",
                width: "100%",
                padding: "0 1em",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 1em",
                }}
              >
                <div>{duration.as("hours").toFixed(1)}</div>
                <div
                  style={{
                    textTransform: "uppercase",
                    opacity: 0.5,
                    fontSize: "50%",
                  }}
                >
                  hours
                </div>
              </div>
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              fontSize: "5vmin",
              padding: "0 1em",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Time timezone={timezone} value={lo} />
            <Time timezone={timezone} value={hi} />
          </div>
        </div>
      ) : null}

      {amount ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "15vmin",
          }}
        >
          ${(amount / 100).toFixed(2)}
        </div>
      ) : (
        "N/A"
      )}

        <h2 style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "6vmin",
          }}>Please Tap/Insert/Swipe</h2>


        { props.retry &&
            <div style={{
                height: "50px",
            }}>
                <div style={{height: "100%", display: "flex"}}>
                    <img style={{height: "100%"}} src={"/tartan.png"}/>
                    <img style={{height: "100%"}} src={"/tartan.png"}/>
                    <img style={{height: "100%"}} src={"/tartan.png"}/>
                    <img style={{height: "100%"}} src={"/tartan.png"}/>
                    <img style={{height: "100%"}} src={"/tartan.png"}/>
                    <img style={{height: "100%"}} src={"/tartan.png"}/>
                </div>
                <h3>FARE EVASION IS A CRIME!</h3>
                <h3>DRIVO reports unpaid parking to the police.</h3>
                <h3>Cars let out from intercom are tracked and footage recorded.<p> Bookings can be made at hotel front desk.</p></h3>
            </div>
        }

      <div
        className="CTA"
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "5vmin",
          whiteSpace: "pre",
          textAlign: "center",
          lineHeight: "7.5vmin",
        }}
      >
        {prompt}
      </div>
    </div>
  )
}

export default Payment;