import { AnimatePresence, motion } from "framer-motion"
import React, {useEffect} from "react"
import { Banner } from "./Banner"
import Chevron from "./Chevron"
import { Clock } from "./Clock"
import Container from "./Container"
import Payment from "./Payment"
import { Weather } from "./Weather"
import {FacilityName} from "./FacilityName";
import PaymentProcessing from "./PaymentProcessing";

const TIMEOUT = 10 * 60 * 1000;

function Sign({
  hello,
  plate,
  screen,
  sign,
}: {
  hello?: any
  plate?: any
  screen?: any
  sign?: any
}): JSX.Element {
  useEffect(() => {
    const timer = setInterval(() => {
      if (screen === "idle" && window.navigator.onLine) {
        console.log("RELOADING")
        window.location.reload()
      }
    }, TIMEOUT)

    if (screen !== "idle") {
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [screen])


    const getKioskIdleText = () => {
        switch (sign?.facility?.id) {
            case "8164db89-8b2b-4339-9939-94eeb27caa0e": // scarborough
                return "Hotel guests, please ensure you have paid at reception before exiting. All other casual parkers, please pay the amount displayed. Thank you."
            default:
                return "Please be patient - plate being read and processed."
        }
    }

    console.log(process.env.NODE_ENV);

    return (
    <Container>
      <Weather {...{ city: sign?.facility?.city }} show={screen === "idle"} />
      <Clock timezone={sign?.facility?.city?.timezone}
        show={screen === "idle"}
      />
      <FacilityName
          show={screen === "idle"}
          name={sign?.facility?.name}
            gate={sign?.gate?.gateDescription}/>
      <Banner show={screen === "awaitingPlate"}>{hello}</Banner>
      <Banner show={screen === "gateOpen"}>Thank you!</Banner>

      <Chevron
        style={{ transition: "all 0.125s linear" }}
        animate={{
          rotateZ:
            screen === "idle"
              ? 0
              : screen === "gateOpen"
              ? 180
              : screen === "payment.checkWhitelist"
              ? 270
              : 90,
        }}
        exit={{ rotateZ: screen === "idle" ? 0 : 90 }}
        thrust={screen !== "idle"}
        fill={
          screen === "payment.checkWhitelist"
            ? "#2196f3"
            : screen === "awaitingPlate"
            ? "#FFAA44"
            : "#32AB40"
        }
      />
        <Banner style={{position: "relative", fontSize: "4vmin" }} show={screen === "idle"}>{getKioskIdleText()}</Banner>

        <AnimatePresence>
        {screen === "payment.tender" ? (
          <motion.div
            transition={{
              rotateZ: {
                type: "spring",
                damping: 33,
                stiffness: 333,
              },
            }}
            initial={{ opacity: 0, y: "-100%" }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{ opacity: 0 }}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Payment {...(sign?.state || {})} timezone={sign?.facility?.city?.timezone} />
          </motion.div>
        ) : null}
      </AnimatePresence>

        <AnimatePresence>
            {screen === "payment.processing" ? (
                <motion.div
                    transition={{
                        rotateZ: {
                            type: "spring",
                            damping: 33,
                            stiffness: 333,
                        },
                    }}
                    initial={{ opacity: 0, y: "-100%" }}
                    animate={{
                        opacity: 1,
                        y: 0,
                    }}
                    exit={{ opacity: 0 }}
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <PaymentProcessing {...(sign?.state || {})} timezone={sign?.facility?.city?.timezone}/>
                </motion.div>
            ) : null}
        </AnimatePresence>

      <Banner
        show={screen === "payment.checkWhitelist"}
        style={{
          alignItems: "flex-end",
          fontFamily: `"Roboto Mono", monospace`,
        }}
      >
        {plate}
      </Banner>
      {/* <pre>{JSON.stringify(sign)}</pre> */}
    </Container>
  )
}

export default Sign;